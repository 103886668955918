<template>
  <div class="base-type-root-container">
    <span class="base-type-title">付款方式</span>
    <div class="base-type-container">
      <div class="base-type-item" v-if="dataSource.pay_type.fixed">
        <img :src="icons.fixed" />
        <span style="font-size: 12px;color: #424242;margin:0px 20px;">固定金额</span>
      </div>
      <div class="base-type-item" v-if="dataSource.pay_type.hour">
        <img :src="icons.hour" />
        <span style="font-size: 12px;color: #424242;margin:0px 20px;">按小时</span>
      </div>
      <div class="base-type-item" v-if="dataSource.pay_type.task">
        <img :src="icons.task" />
        <span style="font-size: 12px;color: #424242;margin:0px 20px;">基于任务</span>
      </div>
      <div class="base-type-item" v-if="dataSource.pay_type.schedule">
        <img :src="icons.schedule" />
        <span style="font-size: 12px;color: #424242;margin:0px 20px;">定期支付</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as icon from "../../../common/icon";
export default {
  props: {
    dataSource: Object,
  },
  data() {
    return {
      icons: icon,
    };
  },
  methods: {},
};
</script>

<style scoped>
.base-type-root-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px 40px;
  background-color: #fff;
  border-radius: 14px;
}
.base-type-title {
  font-size: 15px;
  font-weight: bold;
}
.base-type-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
}
.base-type-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 15px;
  background-color: #e7edf5;
  border-radius: 8px;
  height: 40px;
  margin-right: 30px;
}
</style>