<template>
  <div class="base-root-container">
    <div class="base-scroll-wrapper">
      <div class="base-left-container">
        <base-desc :dataSource="projectDetail" />
        <base-skill style="margin-top:30px;" :dataSource="projectDetail" />
        <base-pay-type style="margin-top:30px;" :dataSource="projectDetail" />
      </div>
      <div class="base-right-container">
        <employer-base />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import BasePayType from "./Panel/BasePayType";
import BaseSkill from "./Panel/BaseSkill";
import BaseDesc from "./Panel/BaseDesc";
import EmployerBase from "./Panel/EmployerBase";
export default {
  components: {
    "employer-base": EmployerBase,
    "base-desc": BaseDesc,
    "base-skill": BaseSkill,
    "base-pay-type": BasePayType,
  },
  data() {
    return {
      projectId: 0,
      projectDetail: {
        pay_type: {
          fixed: false,
          hour: false,
          task: false,
          schedule: false,
        },
      },
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.id;
    this.refresh();
  },
  beforeRouteUpdate(to, from, next) {
    if (this.projectId != to.params.id) {
      this.projectId = to.params.id;
      this.refresh();
    }
    next();
  },
  methods: {
    ...mapActions({
      projectGetBasicAction: "projectGetBasic",
    }),
    refresh() {
      this.projectGetBasicAction({
        employer_id: localStorage.getItem("employer_id"),
        project_id: this.projectId,
      })
        .then((res) => {
          res.pay_type = JSON.parse(res.pay_type);
          res.tags = JSON.parse(res.tags);
          this.projectDetail = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.base-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.base-scroll-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  z-index: 2;
}
.base-left-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 50px;
}
.base-right-container {
  display: flex;
  flex-direction: column;
}
</style>