<template>
  <div class="base-skill-root-container">
    <span class="base-skill-title">技能列表</span>
    <div class="base-skill-tag-container">
      <div v-for="(item,index) in dataSource.tags" :key="index" class="base-skill-tag">
        <Icon type="md-pricetag" />
        <span style="margin-left:5px;margin-right:15px">{{item}}</span>
        <Icon type="md-close" @click="deleteSkill(index)" />
      </div>
    </div>
    <Button type="text" style="color:#3B77BF;width:28px;padding:0" @click="addSkill">添加</Button>

    <Modal v-model="tagModalVisiable" @on-ok="addSkillComplete">
      <Input v-model="tag" placeholder="请输入标签..." style="width: 300px;margin:30px;" />
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  props: {
    dataSource: Object,
  },
  data() {
    return {
      projectId: 0,
      tagModalVisiable: false,
      tag: "",
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.id;
  },
  methods: {
    ...mapActions({
      projectSetTagsAction: "projectSetTags",
    }),
    addSkill() {
      this.tag = "";
      this.tagModalVisiable = true;
    },
    addSkillComplete() {
      this.dataSource.tags.push(this.tag);
      this.submitSkill();
    },
    deleteSkill(index) {
      this.dataSource.tags.splice(index, 1);
      this.submitSkill();
    },
    submitSkill() {
      this.projectSetTagsAction({
        employer_id: localStorage.getItem("employer_id"),
        project_id: this.projectId,
        tags: this.dataSource.tags,
      })
        .then(() => {})
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.base-skill-root-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px 40px;
  background-color: #fff;
  border-radius: 14px;
}
.base-skill-title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 30px;
}
.base-skill-tag-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.base-skill-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: #f4f4f4;
}
</style>