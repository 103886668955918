<template>
  <div class="base-desc-root-container">
    <img @click="edit" class="base-desc-edit" :src="icons.edit" />
    <span class="base-desc-title">项目描述</span>
    <p>{{dataSource.more}}</p>
    <base-desc-drawer ref="baseDescDrawer" />
  </div>
</template>

<script>
import BaseDescDrawer from "../Drawer/BaseDescDrawer";
import * as icon from "../../../common/icon";
export default {
  props:{
    dataSource:Object
  },
  components: {
    "base-desc-drawer": BaseDescDrawer,
  },
  data() {
    return {
      icons: icon,
    };
  },
  methods: {
    edit() {
      this.$refs.baseDescDrawer.open(this.dataSource.more);
    },
  },
};
</script>

<style scoped>
.base-desc-root-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px 40px;
  background-color: #fff;
  border-radius: 14px;
}
.base-desc-title{
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 40px;
}
.base-desc-edit {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 23px;
  height: 23px;
  cursor: pointer;
}
</style>