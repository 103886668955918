<template>
  <my-drawer ref="baseDescDrawer" title="项目描述">
    <template v-slot:main>
      <div style="display:flex;flex-direction:column;">
        <my-textarea ref="formDesc" title="项目描述" :rows="10" v-model="desc" />
      </div>
    </template>
    <template v-slot:footer>
      <Button size="large" type="primary" style="width:100px;" @click="submit">保存</Button>
    </template>
  </my-drawer>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import MyTextArea from "../../../components/Form/MyTextArea";
import MyDrawer from "../../../components/MyDrawer";
export default {
  components: {
    "my-drawer": MyDrawer,
    "my-textarea": MyTextArea,
  },
  data() {
    return {
      projectId: 0,
      desc: "",
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.id;
  },
  methods: {
    ...mapActions({
      projectSetMoreAction: "projectSetMore",
    }),
    open(desc) {
      this.desc = desc;
      this.$refs.baseDescDrawer.open();
    },
    submit() {
      this.projectSetMoreAction({
        employer_id: localStorage.getItem("employer_id"),
        project_id: this.projectId,
        more: this.desc,
      })
        .then(() => {
          this.$parent.$parent.refresh();
          this.$refs.baseDescDrawer.close();
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
</style>